<template>
  <b-modal
    id="modal-contactGroup-detail"
    ref="refModalContactGroupDetail"
    title="Nhóm khách hàng"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        Thông tin nhóm
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tên nhóm:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tagsArray">
          <b-row>
            <b-col class="font-weight-bolder">
              Tags:
            </b-col>
            <b-col class=" ">
              <b-badge
                v-for="item in itemLocal.tagsArray"
                :key="item"
                pill
                variant="light-secondary"
                class="mr-50"
              >
                {{ item }}
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import useContactGroupDetailModal from './useContactGroupDetailModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BBadge,
  },
  props: {
    itemId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalContactGroupDetail,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useContactGroupDetailModal(props, emit, refFormObserver);

    return {
      refModalContactGroupDetail,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      parseDateToString,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
