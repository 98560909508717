import useJwt from '@/auth/jwt/useJwt';

const path = 'contact-group';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAnalytics(ctx, params) {
      return useJwt.getAnalytic(path, params).then(response => response);
    },
    getItems(ctx, params) {
      return useJwt.getItems(path, params).then(response => response);
    },
    getItemDetail(ctx, id) {
      return useJwt.getItemDetail(path, id).then(response => response);
    },
    createItem(ctx, data) {
      return useJwt.createItem(path, data).then(response => response);
    },
    updateItem(ctx, data) {
      return useJwt.updateItem(path, data).then(response => response);
    },
    importItem(ctx, data) {
      return useJwt.importItem(path, data).then(response => response);
    },
    exportItem(ctx, data) {
      return useJwt.exportItem(path, data).then(response => response);
    },
    deleteItem(ctx, data) {
      return useJwt.deleteItem(path, data).then(response => response);
    },
  },
};
