import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import contactGroupStoreModule from '../contactGroupStoreModule';

export default function useContactGroupList() {
  const APARTMENT_STORE_MODULE_NAME = "contactGroup";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, contactGroupStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [

    {
      label: 'Mã',
      field: 'code',
      width: '100px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      width: '120px',
      sortable: false,
    },
    {
      label: 'Tên nhóm',
      field: 'name',
    },
    {
      label: 'Nhãn',
      field: 'tagsArray',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchAnalytics = () => {
    store.dispatch('contactGroup/getAnalytics').then(response => {
      analytics.value = response.data;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };
  const fetchContactGroups = () => {
    store
      .dispatch('contactGroup/getItems', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchContactGroups();
    fetchAnalytics();
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('contactGroup/importItem', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        const vm = getCurrentInstance().proxy;
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('contactGroup/exportItem', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteContactGroups = contactGroups => {
    store
      .dispatch('contactGroup/deleteItem', {
        ids: contactGroups.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onSelectItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    analytics,
    item,
    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    fetchContactGroups,
    deleteContactGroups,
    onSelectItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    parseDateToString,
  };
}
